<template>
  <section class="section is-main-section">
    <div class="columns">
        <div class="column is-one-third">
          <card-component
          title="1. Run a SOM analysis"
          class="tile is-child"
          :displayHelp="$options.name"
          >
          <validation-observer v-slot="{ handleSubmit }">
            <validation-provider
                v-slot="{ errors }"
                :rules= {required:true}
                name="dataset"
              >
                <dataset-select
                label="Select a dataset"
                :dataset.sync="dataset"
                v-model="dataset"
                :withVarname="false"
                icon="table"
                pack="fa"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                expanded
                :minNumericVariable="2"
              />
            </validation-provider>
            <b-field
              label="Topology"
            >
               <b-select
                  v-model="topo"
                  expanded
                >
                  <option>hexagonal</option>
                  <option>square</option>
                </b-select>
            </b-field>
            <validation-provider
                v-slot="{ errors }"
                :rules= {integer:true,min_value:2,max_value:10}
                name="Map length"
                >
                <b-field
                label="Map length"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                >
                    <b-input
                        v-model="mapLength"
                        expanded
                    ></b-input>
                </b-field>
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                :rules= {integer:true,min_value:2,max_value:10}
                name="Map width"
                >
                <b-field
                label="Map width"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                >
                    <b-input
                        v-model="mapWidth"
                        expanded
                    ></b-input>
                </b-field>
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                :rules= {integer:true,min_value:1,max_value:65535}
                name="seed"
                >
                <b-field
                label="Seed"
                :type="{ 'is-danger': errors[0] }"
                :message="messageSeed(errors)"
                >
                    <b-input
                        v-model="seed"
                        expanded
                    ></b-input>
                </b-field>
            </validation-provider>
            <hr />
            <div class="has-text-centered">
              <b-button rounded
                  @click="handleSubmit(run)"
                  class="is-primary button-shadow"
              >
                  <span class="icon is-small"><i class="fa fa-search"></i></span>
                  <span> Run SOM </span>
              </b-button>
            </div>
            </validation-observer>
          </card-component>
        </div>

        <div class="column">
            <card-component title="2. Explore SOM clustering" ></card-component>
              <error :type='"notifications"' />
              <card-no-result v-if="!resultsLoaded">
              <strong>How to set options?<br></strong>
              Set options to obtain results from the Self-Organizing Map algorithm:
              <ul>
                <li><strong>topology</strong> can either be “square” (usually
                easier to interpret because the output plots always correspond
                exactly to the map organization) or “hexagonal” (usually advised
                topology because all units of the map has the same number of
                neighbors but output plots can sometimes be a bit distorted
                because hexagonal display is not supported for all types of
                plots);</li>
                <li><strong>map length</strong> and <strong>map width</strong>:
                correspond to the number of units of the map in two dimensions.
                If you have no a priori choice, you can keep the default
                values;</li>
                <li><strong>seed</strong>: SOM training involves a stochastic
                process and results can be different at each run. Setting this
                value ensures that you will be able to reproduce the exact same
                result (using the same seed). If you want to check that your
                results are stable, enter different seeds and explore the
                different maps obtained from each seed.</li>
              </ul>
              <br>
              <strong>Important note</strong>: SOM is performed with raw values
              (data are not scaled to unit variance before they are processed).
              <strong>If you want to perform SOM on scaled data</strong>, go to
              the menu “Edit / Dataset edition” and scale your dataset(s) before
              SOM.
            </card-no-result>
            <div v-else>
              <card-component>
                <b-tabs @input="displayResult">
                  <b-tab-item label="Plots" icon="bar-chart" value="plots" />
                  <b-tab-item v-if="basicTables" label="Summary" icon="table" value="basic-table" />
                  <b-tab-item label="Cluster view" icon="th" value="clusterview" />
                </b-tabs>
              </card-component>
              <card-component v-show="currentResult==='plots'">
            <plotly
                v-if="graphicals.length===1"
                :data="graphicals[0]"
              ></plotly>
              <plotly-list
                v-else-if="graphicals.length>1"
                :data="graphicals"
              ></plotly-list>
          </card-component>
          <div v-if="currentResult==='basic-table'">
            <card-component
              v-for="(bTable,index) in basicTables"
              :key="index"
              :title="bTable.title"
            >
              <basic-table
                :data="bTable"
              />
            </card-component>
          </div>

          <card-component
              v-if="currentResult==='clusterview'"
              :title="clusterView.title"
            >
            <data-view :data="clusterView" :showClassInfo="true" :sort="true"/>
            <br>
            <r-extract-cluster :datasetName="objectClusters" :baseName="datasetUserName"></r-extract-cluster>
          </card-component>
        </div>
        </div>
      </div>
  </section>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { useWorkspaceStore } from '@/stores/workspacestore'
export default {
  name: 'RSOMStep',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'data-view': () => import('@/components/rresults/DataView.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'error': () => import('@/components/ui/Error.vue'),
    'r-extract-cluster': () => import('@/components/ranalyses/RExtractDataset.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      dataset: '',
      datasetObject: null,
      seed: '',
      objectSom: '',
      objectClusters: '',
      mapLength: 2,
      mapWidth: 2,
      topo: 'hexagonal',
      currentResult: 'plots'
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    getSeed: function () {
      return this.workspaceStore.getSeed
    },
    params: function () {
      let params = {
        'func_name': 'r_som',
        'datasetName': this.dataset,
        'mapLength': parseInt(this.mapLength),
        'mapWidth': parseInt(this.mapWidth),
        'topo': this.topo,
        'out_graph': true
      }
      if (this.seed !== '') {
        params['seed'] = parseInt(this.seed)
      }
      return params
    },
    disableButton: function () {
      if (this.dataset === null) {
        return true
      }
      return false
    },
    clusterView: function () {
      return this.dataViews.filter(el => el.title.startsWith('View of an extract of dataset'))[0]
    },
    datasetUserName: function () {
      let origin = this.workspaceStore.analyses.filter(obj => obj.object_name === this.objectSom)[0].origin_dataset
      return this.workspaceStore.datasets.filter(obj => obj.object_name === origin)[0].user_name
    }
  },
  mounted () {
    this.seed = this.getSeed
    if (this.analysisObj) {
      this.dataset = this.analysisObj.meta.func_args.datasetName
    }
  },
  watch: {
    dataset: function () {
      if (this.dataset) {
        this.datasetObject = this.workspaceStore.datasets.filter(obj => obj.object_name === this.dataset)[0]
        this.mapLength = parseInt(Math.max(5, Math.sqrt(this.datasetObject.meta.nrow / 10)))
        this.mapWidth = this.mapLength
      }
    }
  },
  methods: {
    messageSeed: function (err) {
      let msg = 'You can fix the random seed used to ensure reproductibility of the analysis.'
      if (err.length > 0) {
        msg += ' Error: ' + err
      }
      return msg
    },
    afterRun: function (data) {
      this.displayResult('plots')
      this.objectSom = data.ObjectName.som
      this.objectClusters = data.ObjectName.clusters
      this.$emit('is-computed',
        { 'som': this.objectSom,
          'clusters': this.objectClusters,
          'datasetName': this.dataset })
    },
    displayResult: function (value) {
      this.currentResult = value
    }

  }
}
</script>
<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
  ul {
  list-style: inside;
  padding-left: 10px;
  }
</style>
